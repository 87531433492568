import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgDistribution1 from "../assets/images/mecanicien-remplacement-courroie-distribution.webp";
import ImgDistribution2 from "../assets/images/remplacement-courroie-distribution.webp";

function Distribution () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Distribution</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Remplacement et Entretien de la Courroie de Distribution</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgDistribution1} loading='lazy' className="animation-img-articles w-2/4" alt="Mécanicien effectuant un remplacement de courroie de distribution sur une voiture"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Distribution - Remplacement et Entretien de la Courroie de Distribution</h3>
                    <p className="text-center md:text-left mt-2"><strong>La courroie de distribution est l'un des composants les plus importants de votre moteur</strong>. Elle <strong>synchronise les mouvements des soupapes et des pistons</strong>, garantissant ainsi le bon fonctionnement du moteur. Une courroie de distribution <strong>usée</strong> ou <strong>défaillante</strong> peut provoquer de graves dommages à votre moteur, nécessitant des <strong>réparations coûteuses</strong>.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Quand remplacer la courroie de distribution ?</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Kilométrage recommandé</strong> : Il est généralement conseillé de remplacer la courroie de distribution tous les <strong>100 000 à 150 000 km</strong>, selon les recommandations du fabricant de votre véhicule.</li>
                        <li><strong>Durée en années</strong> : Même si le kilométrage recommandé n'est pas atteint, la courroie doit être remplacée tous les <strong>5 à 7 ans</strong>, car elle <strong>s'use avec le temps</strong>, même si elle n'est <strong>pas beaucoup sollicitée</strong>.</li>
                        <li><strong>Signes d'usure</strong> : Si vous entendez des <strong>bruits de claquement ou de grincement provenant du moteur</strong>, cela pourrait indiquer une <strong>usure de la courroie ou des galets</strong>. Un contrôle immédiat est recommandé pour éviter tout dommage.</li>
                    </ul>
                </div>
                <img src={ImgDistribution2} loading='lazy' className="animation-img-articles w-2/4" alt="Vue détaillée du moteur avec courroie de distribution en cours de remplacement"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Notre service de distribution comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Remplacement de la courroie de distribution</h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Si votre <strong>courroie est usée</strong> ou a <strong>atteint son kilométrage limite</strong>, nous procédons au remplacement complet de la courroie, conformément aux spécifications du fabricant de votre véhicule. Nous utilisons des pièces de haute qualité pour assurer la longévité de votre nouveau kit de distribution.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Changement des galets tendeurs et de la pompe à eau</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Lors du remplacement de la courroie de distribution, il est souvent <strong>recommandé de changer également les galets tendeurs et la pompe à eau</strong>, car ils <strong>s'usent en même temps que la courroie</strong>. Cela garantit un fonctionnement optimal du système de distribution et <strong>évite des réparations supplémentaires à court terme</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Réglage et alignement du système de distribution</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>Après le remplacement de la courroie</strong>, nos techniciens effectuent un réglage précis du système de distribution pour s'assurer que tout fonctionne parfaitement. Cela inclut l'<strong>alignement des pièces</strong> et la <strong>vérification de la tension</strong> de la nouvelle courroie.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="text-sm"><strong>Q: </strong>À quel kilométrage dois-je remplacer ma courroie de distribution ?</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>En général, la courroie de distribution doit être remplacée tous les <strong>100 000 à 150 000 km</strong>. Il est également recommandé de la remplacer <strong>tous les 5 à 7 ans</strong>, même si le kilométrage n'est pas atteint, car le matériau de la courroie s'use avec le temps.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="text-sm"><strong>Q: </strong>Quels sont les signes d'une courroie de distribution usée ?</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Les signes d'usure peuvent inclure des <strong>bruits de grincement ou de claquement provenant du moteur</strong>, des <strong>difficultés à démarrer</strong>, ou un <strong>moteur qui cale</strong>. Si vous remarquez l'un de ces symptômes, il est temps de faire inspecter la courroie de distribution..
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>Dois-je remplacer la pompe à eau en même temps que la courroie de distribution ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Oui, il est généralement recommandé de remplacer la pompe à eau en même temps que la courroie de distribution, car <strong>ces composants s'usent ensemble</strong>. Cela permet d'<strong>éviter des réparations supplémentaires à court terme</strong> et garantit un fonctionnement optimal du moteur.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour une inspection de votre courroie de distribution</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Ne prenez pas de risque avec votre moteur. Si vous avez atteint le kilométrage recommandé ou si vous soupçonnez une usure de la courroie de distribution, contactez dès aujourd'hui Garage Ferrand au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour un contrôle complet ou un remplacement de courroie de distribution.</p>
                </div>
            </div>
        </div>
    );
}

export default Distribution;