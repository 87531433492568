import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgClimatisation1 from "../assets/images/entretien-climatisation.webp";
import ImgClimatisation2 from "../assets/images/mecanicien-entretien-climatisation.webp";

function Climatisation () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Climatisation</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Service d'entretien et recharge de climatisation au Bailleul</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgClimatisation1} loading='lazy' className="animation-img-articles w-2/4" alt="Mécanicien effectuant une recharge de climatisation"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Climatisation - Entretien et Recharge chez Garage Ferrand</h3>
                    <p className="text-center md:text-left mt-2">La <string>climatisation</string> de votre véhicule est essentielle pour votre confort, surtout pendant les chaudes journées d'été. Au fil du temps, elle perd de son efficacité et peut même causer des problèmes si elle n'est pas entretenue correctement. Chez Garage Ferrand, nous proposons des services complets d'<strong>entretien</strong> et de <strong>recharge</strong> de <strong>climatisation</strong> pour garantir que votre système fonctionne toujours de manière optimale.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Quand recharger ou entretenir votre climatisation ?</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Tous les 2 ans</strong> : Il est recommandé de faire vérifier et recharger la climatisation de votre véhicule tous les deux ans pour maintenir ses performances.</li>
                        <li><strong>Signes de dysfonctionnement</strong> : Si vous remarquez une diminution de la puissance de refroidissement, des bruits inhabituels, ou des odeurs désagréables, il est temps de faire vérifier votre système par un professionnel.</li>
                    </ul>
                </div>
                <img src={ImgClimatisation2} loading='lazy' className="animation-img-articles w-2/4" alt="Mécanicien effectuant une recharge de climatisation"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Notre service de climatisation comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Recharge de fluide réfrigérant</h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                            La <strong>recharge</strong> est une étape essentielle pour assurer un fonctionnement optimal de votre climatisation. Nous utilisons les deux types de fluides les plus courants : <strong>R134A</strong> pour les véhicules plus anciens et <strong>R1234YF</strong> pour les modèles plus récents, respectueux de l'environnement.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Entretien complet de la climatisation</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Nous effectuons un contrôle minutieux de l'ensemble du système de climatisation, incluant : la <strong>vérification des filtres</strong> et du fluide réfrigérant, le <strong>nettoyage</strong> du circuit de climatisation pour éliminer les <strong>bactéries</strong> et prévenir les mauvaises odeurs et l'<strong>inspection des compresseurs, condenseurs</strong>, et autres composants pour détecter tout dysfonctionnement.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Diagnostic et réparation de climatisation</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Si vous rencontrez des problèmes avec votre climatisation, notre équipe d'experts est à votre disposition pour diagnostiquer et <strong>réparer les pannes</strong>, qu'il s'agisse de <strong>fuites</strong>, de <strong>compresseurs défectueux</strong> ou de tout autre problème technique.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="text-sm"><strong>Q: </strong>À quelle fréquence dois-je recharger ma climatisation ? </h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Il est recommandé de vérifier et de recharger le fluide de climatisation <strong>tous les deux ans</strong> pour maintenir des performances optimales..
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="text-sm"><strong>Q: </strong>Que faire si ma climatisation émet une mauvaise odeur ?</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Une odeur désagréable peut indiquer <strong>la présence de bactéries dans le système</strong>. Nous recommandons un nettoyage complet de votre circuit de climatisation pour éliminer ces problèmes.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>Quels sont les signes que ma climatisation a besoin d’un entretien ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Si vous remarquez une <strong>baisse de la puissance</strong> de refroidissement, des <strong>bruits inhabituels</strong> ou des <strong>odeurs désagréables</strong>, il est temps de faire contrôler votre climatisation.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour entretenir votre climatisation</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">N'attendez pas que votre climatisation tombe en panne avant de la faire entretenir. Contactez-nous dès aujourd'hui pour un diagnostic rapide et une recharge de votre système de climatisation.Vous pouvez nous joindre au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a>. Nous nous engageons à vous offrir un service rapide et efficace pour que vous puissiez reprendre la route en toute sérénité.</p>
                </div>
            </div>
        </div>
    );
}

export default Climatisation;