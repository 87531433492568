import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { IoMail } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";

function Contact () {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm('service_h04fy41', 'template_iwonfrp', form.current, {
            publicKey: 'd_5RhIMKYArrU9DFF',
        })
        .then(
            (result) => {
                console.log('SUCCESS!');
                window.location.href = "/";
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
        );
    };

    return (
        <div className='pt-32 md:pt-48 pb-10 flex flex-col justify-center gap-10 mx-10 lg:mx-56 3xl:mx-96'>
            <div className='flex flex-row justify-center gap-10'>
                <a href="mailto:ferrand87@orange.fr" className="bg-bg p-2 md:p-10 md:w-64 md:h-32 rounded-lg shadow-md text-center flex flex-col items-center justify-center gap-2 hover:font-semibold cursor-pointer">
                    <div>
                        <IoMail className='w-8 h-8 text-primary' aria-label="Icône Email"/>
                    </div>
                    <p className='text-primary hidden md:block'>ferrand87@orange.fr</p>
                </a>
                <a href="tel:0243455023" className="bg-bg p-2 md:p-10 md:w-64 md:h-32 rounded-lg shadow-md text-center flex flex-col items-center justify-center gap-2 hover:font-semibold cursor-pointer">
                    <div>
                        <FaPhone className='w-8 h-8 text-primary' aria-label="Icône Téléphone"/>
                    </div>
                    <p className='text-primary hidden md:block'>02 43 45 50 23</p>
                </a>
                <a href="https://www.facebook.com/pferrand74" className="bg-bg p-2 md:p-10 md:w-64 md:h-32 rounded-lg shadow-md text-center flex flex-col items-center justify-center gap-2 hover:font-semibold cursor-pointer">
                    <div>
                        <FaFacebook className='w-8 h-8 text-primary' aria-label="Icône Facebook"/>
                    </div>
                    <p className='text-primary hidden md:block'>Pascal Ferrand</p>
                </a>
            </div>
            <div className="bg-bg rounded-lg shadow-md md:mx-28">
                <form ref={form} onSubmit={sendEmail} action='' className='flex flex-col items-center  py-10 px-0 md:px-10'>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4 mb-4 w-72 md:w-full">
                        <input type='text' placeholder='Votre nom' required name="nom" className="flex items-center justify-between py-2 px-4 rounded-full mb-4 md:mb-0"/>
                        <input type='text' placeholder='Votre prénom' required name="prenom" className="flex items-center justify-between py-2 px-4 rounded-full mb-4 md:mb-0"/>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4 mb-4 w-72 md:w-full">
                        <input type="text" placeholder='Votre téléphone' required name='phone' className="flex items-center justify-between py-2 px-4 rounded-full mb-4 md:mb-0"/>   
                        <input type="email" placeholder='Votre email' name="email" className="flex items-center justify-between py-2 px-4 rounded-full"/>
                    </div>

                        
                    <textarea name="message" placeholder='Une description de votre besoin' className="flex items-center justify-between w-72 md:w-full px-4 text-left mb-10 pb-20 pt-4 rounded-xl"></textarea>

                    <input type='submit' value="Envoyer"  className="border-2 border-primary text-primary hover:bg-primary hover:text-white rounded-full py-1 px-4"/>  
                </form>
            </div>
        </div>
    );
}

export default Contact;