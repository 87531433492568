import React, { useRef, useState, useEffect } from 'react';
import MapIframe from './Maps';
import reviewsData from './reviewsData.json'; 

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

// Icons
import { IoIosArrowDown } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { IoIosStar } from "react-icons/io";
import { FaComment } from "react-icons/fa";

// Images
import ImageGarage from "./assets/images/garage-automobile-Ferrand-au-Bailleul.webp";
import IconVidange from "./assets/images/vidange.webp"
import IconDiagnostic from "./assets/images/diagnostic.webp";
import IconClimatisation from "./assets/images/climatisation.webp";
import IconPneumatique from "./assets/images/pneumatique.webp";
import IconFreinage from "./assets/images/freinage.webp";
import IconReglageGeometrie from "./assets/images/reglage-geometrie.webp";
import IconDistribution from "./assets/images/distribution.webp";
import IconEmbrayage from "./assets/images/embrayage.webp";
import IconPareBrise from "./assets/images/pare-brise.webp";
import IconEclairage from "./assets/images/eclairage.webp"

function ReviewCard({ author, review, rating }) {
    const [showFullReview, setShowFullReview] = useState(false);
  
    const toggleReview = () => {
      setShowFullReview(!showFullReview);
    };
  
    // Limiter l'affichage à 150 caractères, puis ajouter "Lire la suite" si le texte est plus long
    const reviewPreview = review.length > 150 ? review.substring(0, 150) + "..." : review;

    const renderStars = (rating) => {
        const fullStars = Math.floor(rating);
        const stars = [];
        for (let i = 0; i < 5; i++) {
          stars.push(i < fullStars ? <IoIosStar className='text-yellow-400' aria-label="Étoile de notation" key={i} /> : <IoIosStar className='text-gray-600' aria-label="Étoile de notation" key={i} />);
        }
        return stars;
      };
  
    return (
      <div className="review-card flex flex-col items-center justify-center gap-2">
        <h3 className='text-primary font-semibold text-sm md:text-base'>{author}</h3>
        <p className='flex'>{renderStars(rating)}</p>
        <p>{showFullReview ? review : reviewPreview}</p>
        {review.length > 150 && (
          <button onClick={toggleReview}>
            {showFullReview ? "Lire moins" : "Lire la suite"}
          </button>
        )}
      </div>
    );
  }

function Home() {
    const prevRefService = useRef(null);
    const nextRefService = useRef(null);
    const prevRefReview = useRef(null);
    const nextRefReview = useRef(null);  

    useEffect(() => {
        const schemaLocalBusiness = {
            "@context": "https://schema.org",
            "@type": "AutoRepair",
            "name": "Garage Ferrand",
            "image": "https://garageferrand.fr/assets/images/garage-automobile-Ferrand-au-Bailleul.webp",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "30 rue du portugal",
              "addressLocality": "Le Bailleul",
              "addressRegion": "Pays de la Loire",
              "postalCode": "72200",
              "addressCountry": "FR"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 47.7733497,
              "longitude": -0.2120999
            },
            "telephone": "+33243455023",
            "openingHours": "Mo-Fr 08:00-19:00"
        }; 
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(reviewsData); // Transformer le JSON en chaîne de caractères
        document.head.appendChild(script); // Ajouter le script dans le head
    
        return () => {
          document.head.removeChild(script); // Nettoyer le script quand le composant est démonté
        };
      }, []);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96">
            {/* Hero Section */}
            <div className="md:min-h-screen flex flex-col lg:flex-row items-center pt-32 md:pt-48 lg:pt-24">
                <div className="md:mr-24 flex gap-4 md:gap-8 flex-col">
                    <h1 className='text-center lg:text-left text-2xl md:text-3xl'>Garage Ferrand Le Bailleul Bosch Car Services</h1>
                    <p className='text-center lg:text-left'><strong>Notre garage</strong>, situé au <strong>Bailleul</strong> à côté du <strong>Pôle Santé</strong>, est bien plus qu'un simple lieu de <strong>réparation automobile</strong>. Spécialisé dans le <strong>dépannage autoroute</strong>, l'<strong>entretien de véhicules</strong> toutes marques et le <strong>diagnostic automobile</strong>, il offre une gamme complète de services pour répondre aux besoins variés des conducteurs.</p>
                    <a href="#services" className="hidden lg:flex items-center">
                        <div className="bg-primary text-white rounded-xl">
                            <IoIosArrowDown aria-label="Flèche vers le bas" className="w-9 h-9 p-2 animation-icon"/>
                        </div>
                        <p className="ml-3">Suivant</p>
                    </a>
                </div>
                <img src={ImageGarage} loading='lazy' alt="Bâtiment du Garage Ferrand au Bailleul, avec enseigne Bosch Car Service et slogan 'Nous faisons tout pour votre voiture' sur la façade" className="animation-img mt-10 lg:mt-0"/>
            </div>

            {/* Services Section */}
            <div id="services" className='pt-36 pb-36'>
                <h2 className='text-center pb-4 text-xl md:text-2xl'>Nos services</h2>
                <p className='text-center pb-8 md:px-24'>Découvrez l'ensemble de nos services dédiés à l'<strong>entretien</strong> et à la <strong>réparation</strong> de votre véhicule. Du <strong>diagnostic</strong> automobile à la <strong>vidange</strong>, en passant par la <strong>révision</strong> et le <strong>dépannage</strong>, nous proposons des solutions adaptées à toutes les marques pour garantir votre sécurité et prolonger la durée de vie de votre voiture.</p>
                <div className='relative px-16'>
                    <button ref={prevRefService} className="absolute left-0 top-1/2 z-10 -translate-y-1/2 bg-primary text-white p-2 rounded-full">
                        <FaArrowLeft aria-label="Flèche vers la gauche"/>
                    </button>
                    <button ref={nextRefService} className="absolute right-0 top-1/2 z-10 -translate-y-1/2 bg-primary text-white p-2 rounded-full">
                        <FaArrowRight aria-label="Flèche vers la droite"/>
                    </button>
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        spaceBetween={30}
                        breakpoints={{
                            640: {
                                slidesPerView: 3,
                            },
                            1000: {
                                slidesPerView: 4,
                            }
                        }}
                        navigation={{
                            prevEl: prevRefService.current, // Link to previous button
                            nextEl: nextRefService.current, // Link to next button
                        }}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRefService.current;
                            swiper.params.navigation.nextEl = nextRefService.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        loop={true}
                        className="mySwiper h-56" 
                    >
                        <SwiperSlide>
                            <a href="/services/vidange" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconVidange} loading='lazy' alt="Service de révision et vidange automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Révision / Vidange</h4>
                            </a> 
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/diagnostic" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconDiagnostic} loading='lazy' alt="Service de diagnostic automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Diagnostic</h4>
                            </a> 
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/climatisation" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconClimatisation} loading='lazy' alt="Service de climatisation automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Climatisation</h4>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/pneumatique" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconPneumatique} loading='lazy' alt="Service de pneumatique automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Pneumatique</h4>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/freinage" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconFreinage} loading='lazy' alt="Service de freinage automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Freinage</h4>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/reglage-geometrie" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconReglageGeometrie} loading='lazy' alt="Service de réglage géométrie automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Réglage Géométrie</h4>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/distribution" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconDistribution} loading='lazy' alt="Service de distribution automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Distribution</h4>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/embrayage" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconEmbrayage} loading='lazy' alt="Service d'embrayage automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Embrayage</h4>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/pare-brise" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconPareBrise} loading='lazy' alt="Service de pare-brise automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Pare-brise</h4>
                            </a>
                        </SwiperSlide>

                        <SwiperSlide>
                            <a href="/services/eclairage" className="bg-bg p-10 w-52 h-52 rounded-lg shadow-md text-center flex flex-col items-center hover:font-semibold hover:cursor-pointer">
                                <img src={IconEclairage} loading='lazy' alt="Service d'éclairage automobile au Garage Ferrand" className="mx-auto mb-4 w-16 h-16" />
                                <h4>Eclairage</h4>
                            </a>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <a href='/services' className='mt-12 bg-primary text-white text-center py-2 rounded-full w-60 absolute left-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:font-bold'>Découvrir nos services</a>
            </div>

            {/* Contact Section */}
            <div id='contact' className='min-h-screen flex flex-col items-center justify-center'>
                <h2 className='text-center pb-4 text-xl md:text-2xl'>Nous contacter</h2>
                <div className='flex flex-col-reverse md:flex-row gap-10'>
                    {/* left */}
                    <div className='flex flex-row md:flex-col justify-center gap-4'>
                        <a href="mailto:ferrand87@orange.fr" className="bg-bg p-2 md:p-10 md:w-64 md:h-32 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:font-semibold hover:cursor-pointer">
                            <IoMail className='w-12 h-12 text-primary' aria-label="Icône Email"/>
                            <p className='text-primary hidden md:block'>ferrand87@orange.fr</p>
                        </a>
                        <a href="tel:0243455023" className="bg-bg p-2 md:p-10 md:w-64 md:h-32 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:font-semibold hover:cursor-pointer">
                            <FaPhone className='w-12 h-12 text-primary' aria-label="Icône Téléphone"/>
                            <p className='text-primary hidden md:block'>02 43 45 50 23</p>
                        </a>
                        <a href="https://www.facebook.com/pascal.ferrand.50" target="_blank" rel="noreferrer" className="bg-bg p-2 md:p-10 md:w-64 md:h-32 rounded-lg shadow-md text-center flex flex-col items-center justify-center hover:font-semibold hover:cursor-pointer">
                            <FaFacebook className='w-12 h-12 text-primary' aria-label="Icône Facebook"/>
                            <p className='text-primary hidden md:block'>Pascal Ferrand</p>
                        </a>
                    </div>
                    {/* right */}
                    <div className='flex flex-col'>
                        <p className='bg-bg rounded-lg py-2 px-8 text-primary font-semibold mb-4 text-center md:text-left'>Nos horaires d'ouverture : Du Lundi au Vendredi, de 8h00 à 19h00</p>
                        <MapIframe/>
                        <a href="/contact" className='w-full mt-4 bg-primary text-white py-2 rounded-full text-center hover:font-bold'>Nous contacter</a>
                    </div>
                </div>
            </div>

            <div id="avis" className='my-24'>
                <h2 className='text-center pb-4 text-xl md:text-2xl'>Ce que disent nos clients</h2>
                <div className='relative px-16' data-cookieconsent="ignore">
                    <button ref={prevRefReview} className="absolute left-0 top-1/2 z-10 -translate-y-1/2 bg-primary text-white p-2 rounded-full">
                        <FaArrowLeft aria-label="Flèche vers la gauche"/>
                    </button>
                    <button ref={nextRefReview} className="absolute right-0 top-1/2 z-10 -translate-y-1/2 bg-primary text-white p-2 rounded-full">
                        <FaArrowRight aria-label="Flèche vers la droite"/>
                    </button>
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        spaceBetween={30}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                            },
                            1000: {
                                slidesPerView: 3,
                            }
                        }}
                        navigation={{
                            prevEl: prevRefReview.current, // Link to previous button
                            nextEl: nextRefReview.current, // Link to next button
                        }}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRefReview.current;
                            swiper.params.navigation.nextEl = nextRefReview.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        loop={true}
                        className="mySwiper">
                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="Nicolas Nico"
                                review="Garage hors du commun ! Un injecteur m'a lâché sur l'autoroute, j'ai pu tant bien que mal me rendre dans ce garage où j'ai été pleinement pris en charge :  diagnostic, commande de l'injecteur car non dispo en stock, récupération dans la foulée dans 2 établissements éloignés de près de 15 km du garage puis installation, j'ai pu directement reprendre la route. On nous a également déposé à un endroit où j'ai pu prendre de quoi manger pour mes enfants et moi même. Bref, je recommande vivement cet établissement pour ces  grandes qualités techniques et humaines !"
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="Antonina Lemoine"
                                review="Un vendredi soir 18h , une banale crevaison sur l’autoroute , en 30 mins nous étions remorqués , en 30 mins l’intervention était effectuée et le dossier d’assurance géré. Les gérants sont en plus très agréables et très accueillants , je vous recommande vivement ce garage"
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="Sébastien Henaux"
                                review="Crevaison sur autoroute, je sors 3kms après et voulant me rendre à norauto à 14 kms je tombe sur ce garage. Une personne réactive et en moins de 15 mns je reprends  la route. Merci beaucoup"
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="Inès Tabaï"
                                review="Merci à Sandrine la gérante de ce garage  pour son accueil et sa gentillesse. Nous avons pu profiter d'un bon repas grâce à elle. Merci encore"
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="Sébastien LEBLANC"
                                review="Visite suite affichage panne sur tableau de bord. Très bon accueil, lecture code erreur sur valise, le garagiste qui devait être le gérant m'a rassuré. Très bon contact qui inspire confiance. Je recommande et j'y retournerai car j'habite vraiment pas loin."
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="john vitu (DEASTEK)"
                                review="depannage sur auto-route et très arrangeant, d’ailleurs si vous souhaitez un site web pour votre garage deastek.fr je serais ravi de vous proposer un site web en location"
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="Émeric GAUTIER"
                                review="Je suis satisfait de la prestation du garage de Mr Ferrand. Bon courage à vous et merci!"
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="Kevin GUILLON"
                                review="Nous a dépanner assez rapidement ! Agréable et nous on passer une voiture de dépannage !"
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="Mickaël Catherine"
                                review="Bon garage et location de voitures très pratiques rares de nos jours"
                                rating={5} 
                            />
                        </SwiperSlide>

                        <SwiperSlide className='bg-bg rounded-3xl text-center p-4 shadow-md'>
                            <ReviewCard
                                author="jim"
                                review="Garagiste sérieux à votre écoute"
                                rating={4} 
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 mt-10 md:mx-16 shadow-md">
                    <FaComment aria-label="Icône Commentaire" className="w-20 md:w-24 h-20 md:h-18 text-3xl text-primary mx-10"/>
                    <div className='flex flex-col items-center md:items-start'>
                        <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mb-2">Votre avis compte !</h3>
                        <p className="text-primary text-center md:text-left text-sm mb-4">Si vous avez fait appel à nos services, nous serions ravis de connaître votre opinion. Laissez un avis et partagez votre expérience avec nous.</p>
                        <a href="https://www.google.fr/maps/place/Garage+Pascal+Ferrand/@47.7733533,-0.2146748,17z/data=!4m8!3m7!1s0x481117e7ef5eb399:0x53afc46dbd157bbd!8m2!3d47.7733497!4d-0.2120999!9m1!1b1!16s%2Fg%2F1tdq0h_y?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D" target='_blank' rel="noreferrer" className='bg-primary text-white text-center py-2 px-10 w-full md:w-56 rounded-full hover:font-bold'>Laisser un avis</a>
                    </div> 
                </div>
            </div>
        </div>
    );
}

export default Home;