import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

// Images
import LogoArcEurope from "../assets/images/logo-arc-europe.webp";
import LogoActa from "../assets/images/logo-ACTA.webp";
import LogoMapfre from "../assets/images/logo-mapfre.webp";
import LogoMondialAssistance from "../assets/images/logo-mondial-assistance.webp";
import LogoOpteven from "../assets/images/logo-opteven.webp";
import LogoEuropAssistance from "../assets/images/logo-europ-assistance.webp";
import LogoSaveAssistance from "../assets/images/logo-save-assistance.webp";
import LogoBnpParibas from "../assets/images/logo-bnp-paribas.webp";
import LogoOney from "../assets/images/logo-oney.webp";
import LogoAxa from "../assets/images/logo-axa.webp";
import LogoAvisBudget from "../assets/images/logo-avis-budget-group.webp";

function Partenaires () {
    return (
        <div data-cookieconsent="ignore">
            <Swiper 
                slidesPerView={2} 
                spaceBetween={30} 
                centeredSlides={true}  
                loop={true} 
                modules={[Autoplay]} 
                className="mySwiper h-32"
                autoplay={{ delay: 1000, disableOnInteraction: false, }}
                breakpoints={{
                    640: {
                        slidesPerView: 4,
                    },
                    1000: {
                        slidesPerView: 6,
                    }
                }}
            >
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoArcEurope} loading='lazy' alt='Logo Arc Europe' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoActa} loading='lazy' alt='Logo Acta' width='130px' height='130px'/>
                    </div>
                </SwiperSlide> 
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoMapfre} loading='lazy' alt='Logo Mapfre' width='110px' height='110px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoMondialAssistance} loading='lazy' alt='Logo Mondial Assistance' width='130px' height='130px'/>
                    </div>
                </SwiperSlide> 
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoOpteven} loading='lazy' alt='Logo Opteven' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoEuropAssistance} loading='lazy' alt='Logo Europ Assistance' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoSaveAssistance} loading='lazy' alt='Logo Save Assistance' width='110px' height='110px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoBnpParibas} loading='lazy' alt='Logo BNP Paribas' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoOney} loading='lazy' alt='Logo Oney' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoAxa} loading='lazy' alt='Logo Axa' width='100px' height='100px'/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='w-32 h-32 flex items-center justify-center'>
                        <img src={LogoAvisBudget} loading='lazy' alt='Logo Avis Budget Group' width='130px' height='130px'/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default Partenaires;