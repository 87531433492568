import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgEclairage1 from "../assets/images/mecanicien-entretien-lustrage.webp";
import ImgEclairage2 from "../assets/images/lustrage.webp";

function Eclairage () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Éclairage</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Service de Contrôle et Entretien des Feux</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgEclairage1} loading='lazy' className="animation-img-articles w-2/4" alt="Mécanicien effectuant un lustrage de phare"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Service de Contrôle et Entretien des Feux chez Garage Ferrand</h3>
                    <p className="text-center md:text-left mt-2">Les feux de votre véhicule jouent un rôle crucial dans votre sécurité sur la route. Que ce soit pour bien voir ou pour être vu des autres conducteurs, <strong>un bon fonctionnement de vos feux est essentiel</strong>. Chez Garage Ferrand, nous offrons une gamme complète de services pour garantir que vos phares, feux arrière, clignotants et autres systèmes d'éclairage fonctionnent parfaitement.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                        <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                        <h4 className="font-semibold text-sm">Contrôle des Feux</h4>
                        {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                        </div>
                        <Collapse isOpened={isOpen1}>
                        <div className="mt-3 pl-3">
                            <p className="text-primary">
                                Le contrôle régulier de l'état de vos feux est indispensable pour assurer votre sécurité et celle des autres usagers de la route. <strong>Nous vérifions la bonne visibilité de vos phares ainsi que leur alignement</strong>, afin d'éviter tout éblouissement des conducteurs venant en sens inverse. De plus, <strong>nous nous assurons que vos clignotants, feux de recul et feux stop fonctionnent parfaitement</strong>.
                            </p>
                        </div>
                        </Collapse>
                    </div>

                    <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                        <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                        <h4 className="font-semibold text-sm">Changement d'Ampoule ou LED</h4>
                        {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                        </div>
                        <Collapse isOpened={isOpen2}>
                        <div className="mt-3 pl-3">
                            <p className="text-primary">
                            Que vous ayez besoin de <strong>remplacer une simple ampoule halogène</strong> ou d'<strong>installer des feux LED plus performants</strong>, nos techniciens se chargent de tout. Nous utilisons des produits de qualité pour garantir une longue durée de vie et une luminosité optimale. Vous pouvez choisir entre différents types d'ampoules <strong>(halogène, xénon, LED)</strong> en fonction de vos besoins et de votre budget.
                            </p>
                        </div>
                        </Collapse>
                    </div>

                    <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                        <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                        <h4 className="font-semibold text-sm">Lustrage des Phares</h4>
                        {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                        </div>
                        <Collapse isOpened={isOpen3}>
                        <div className="mt-3 pl-3">
                            <p className="text-primary">
                            <strong>Avec le temps, les phares peuvent devenir opaques ou jaunir</strong>, ce qui réduit considérablement leur efficacité. Chez Garage Ferrand, nous proposons un service de <strong>lustrage des phares pour restaurer leur clarté et leur brillance</strong>. Grâce à notre technique de polissage, vos phares retrouveront leur état d'origine, améliorant ainsi votre visibilité nocturne et augmentant la durée de vie de vos feux.
                            </p>
                        </div>
                        </Collapse>
                    </div>
                </div>
                <img src={ImgEclairage2} loading='lazy' className="animation-img-articles w-2/4" alt="Vue d'un phare lustré"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="text-sm"><strong>Q: </strong>À quelle fréquence dois-je contrôler l'état de mes feux ?</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Il est recommandé de vérifier l'état de vos feux tous les 6 mois, ou avant un long trajet. Si vous remarquez que vos feux sont moins lumineux, jaunissent, ou que leur alignement semble incorrect, un contrôle chez un professionnel est conseillé.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="text-sm"><strong>Q: </strong>Comment savoir si mes phares ont besoin d'un lustrage ?</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Si vos phares semblent opaques, jaunis ou que leur éclairage est réduit, il est probable qu'ils aient besoin d'un lustrage. Ce traitement permet de restaurer la clarté des phares et d'améliorer leur performance lumineuse.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>Quelle est la différence entre une ampoule halogène, xénon et LED ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Les ampoules halogènes sont les plus courantes, moins chères mais ont une durée de vie plus courte. Les ampoules xénon offrent une lumière plus blanche et plus intense, mais sont plus coûteuses. Les LED sont les plus durables, très lumineuses, et consomment moins d'énergie.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour un contrôle ou remplacement de vos feux</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Assurez-vous que vos phares fonctionnent de manière optimale et que votre visibilité sur la route est irréprochable. Que ce soit pour un contrôle de l'éclairage, un lustrage des phares ou un changement d'ampoule/LED, n'attendez pas que des problèmes surviennent. Contactez dès aujourd'hui Garage Ferrand au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour demander un devis. </p>
                </div>
            </div>
        </div>
    );
}

export default Eclairage;