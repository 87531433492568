import React, { useEffect, useState } from "react";
import Logo from "./assets/images/bosch-car-service-logo.svg";
import { FaPhone } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

function Navbar() {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);
    const [isScrolled, setIsScrolled] = useState(false);
    
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }; 
    }, []);

    return (
        <header className="absolute w-full">
            {/* Navigation Top */}
            <div className="w-96 md:w-11/12 lg:w-full max-w-screen-xl mx-auto flex items-center justify-between my-4 px-12 rounded-full bg-primary z-50">
                <div className="flex items-center gap-4">
                    <img src={Logo} alt="Logo Bosch Car Service" className="w-12 md:w-16"/>
                    <span className="text-white text-sm text-left md:text-center">Nous faisons tout pour votre voiture</span>
                </div>
                <div className="hidden md:flex items-center gap-2 bg-white text-primary rounded-full px-4 py-2 hover:cursor-pointer">
                    <FaPhone aria-label="Icône Téléphone"/>
                    <a href="tel:+0243455023">02 43 45 50 23</a>
                </div>
                <div onClick={handleClick} className='md:hidden z-10 flex items-center gap-10'>
                    {!nav ? <FaBars className='text-2xl text-white' aria-label="Icône Menu"/> : <FaTimes className='text-2xl text-white' aria-label="Icône Croix"/>}
                </div>
            </div>
            <div id="nav-bottom"
                className={`fixed left-0 right-0 max-w-screen-sm mx-auto px-10 mt-10 z-50 transition-all duration-300 ease-in-out ${
                    isScrolled
                        ? 'fixed top-[-2%] bg-primary text-white rounded-full'
                        : 'absolute -translate-y-2/4 bg-transparent text-black'
                }`}
            >
                <ul className="hidden md:flex items-center justify-between">
                    <img
                        id="logo"
                        src={Logo}
                        alt="Logo Bosch Car Service"
                        className={`w-10 ${isScrolled ? 'block' : 'hidden'}`}
                    />
                    <li><a href="/">Accueil</a></li>
                    <li><a href="/services">Nos services</a></li>
                    <li><a href="/contact">Nous contacter</a></li> 
                </ul>
            </div>

            <ul className={!nav ? 'hidden' : 'absolute z-0 top-0 left-0 w-full h-screen bg-primary dark:bg-black-primary flex flex-col justify-center items-center'}>
                <li>
                    <img src={Logo} alt="Logo Bosch Car Service" className="w-16"/>
                </li>
                <li className='py-6 text-2xl font-semibold'>
                    <a href="/" onClick={handleClick} className="text-white">
                        Accueil
                    </a>
                </li>
                <li className='py-6 text-2xl font-semibold'>
                    <a href="/services" onClick={handleClick} className="text-white">
                        Nos services
                    </a>
                </li>
                <li className='py-6 text-2xl font-semibold'>
                    <a href="/contact" onClick={handleClick} className="text-white">
                        Nous contacter
                    </a>
                </li>
            </ul>
        </header>
    );
}

export default Navbar; 