import IconVidange from "./assets/images/vidange.webp"
import IconDiagnostic from "./assets/images/diagnostic.webp";
import IconClimatisation from "./assets/images/climatisation.webp";
import IconPneumatique from "./assets/images/pneumatique.webp";
import IconFreinage from "./assets/images/freinage.webp";
import IconReglageGeometrie from "./assets/images/reglage-geometrie.webp";
import IconDistribution from "./assets/images/distribution.webp";
import IconEmbrayage from "./assets/images/embrayage.webp";
import IconPareBrise from "./assets/images/pare-brise.webp";
import IconEclairage from "./assets/images/eclairage.webp";

function Services () {
    return (
        <div className="pt-32 md:pt-48 pb-10 grid grid-cols-1 md:grid-cols-5 gap-6 mx-10 lg:mx-56 3xl:mx-96">
            <a href="/services/vidange" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconVidange} loading='lazy' alt="Service de révision et vidange automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Révision / Vidange</p>
            </a>
            <a href="/services/diagnostic" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconDiagnostic} loading='lazy' alt="Service de diagnostic automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Diagnostic</p>
            </a>
            <a href="/services/climatisation" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconClimatisation} loading='lazy' alt="Service de climatisation automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Climatisation</p>
            </a>
            <a href="/services/pneumatique" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconPneumatique} loading='lazy' alt="Service de pneumatique automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Pneumatique</p>
            </a>
            <a href="/services/freinage" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconFreinage} loading='lazy' alt="Service de freinage automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Freinage</p>
            </a>
            <a href="/services/reglage-geometrie" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconReglageGeometrie} loading='lazy' alt="Service de réglage géométrie automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Reglage Geometrie</p>
            </a>
            <a href="/services/distribution" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconDistribution} loading='lazy' alt="Service de distribution automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Distribution</p>
            </a>
            <a href="/services/embrayage" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconEmbrayage} loading='lazy' alt="Service d'embrayage automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Embrayage</p>
            </a>
            <a href="/services/pare-brise" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconPareBrise} loading='lazy' alt="Service de pare-brise automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Pare-brise</p>
            </a>
            <a href="/services/eclairage" className="bg-bg p-4 md:p-10 h-20 md:h-48 rounded-lg shadow-md md:text-center flex gap-4 md:gap-0 md:flex-col items-center hover:font-semibold hover:cursor-pointer">
                <img src={IconEclairage} loading='lazy' alt="Service d'éclairage automobile au Garage Ferrand" className="md:mx-auto mb-4 w-12 h-12 md:w-16 md:h-16" />
                <p className="text-lg text-primary">Eclairage</p>
            </a>
        </div>
    );
}

export default Services;