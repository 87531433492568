import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgFreinage1 from "../assets/images/mecanicien-remplacement-disque-plaquette.webp";
import ImgFreinage2 from "../assets/images/remplacement-disque-plaquette.webp";

function Freinage () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);
    const [isOpen7, setIsOpen7] = useState(false);
    const [isOpen8, setIsOpen8] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Freinage</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Réparation et Entretien de votre système de freinage</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgFreinage1} loading="lazy" className="animation-img-articles w-2/4" alt="Mécanicien effectuant un remplacement de disque et plaquette de frein"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Freinage - Réparation et Entretien de votre système de freinage</h3>
                    <p className="text-center md:text-left mt-2">Le système de freinage est <strong>l'un des éléments les plus cruciaux</strong> pour <strong>assurer votre sécurité</strong> sur la route. Des <strong>freins usés</strong> ou <strong>défectueux</strong> peuvent augmenter considérablement les <strong>risques d'accidents</strong> en allongeant les distances de freinage ou en rendant le véhicule difficile à contrôler.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Signes que vos freins ont besoin d'entretien :</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Bruits inhabituels</strong> : Des bruits de grincement ou de frottement lors du freinage peuvent indiquer des plaquettes de frein usées ou des disques de frein endommagés.</li>
                        <li><strong>Vibrations</strong> : Si vous ressentez des vibrations dans le volant ou les pédales lorsque vous freinez, cela pourrait être dû à des disques de frein voilés ou à un problème d'alignement des étriers.</li>
                        <li><strong>Pédale de frein molle</strong> : Si votre pédale de frein devient molle ou nécessite une pression excessive pour freiner, cela peut être dû à une fuite de liquide de frein ou à des problèmes au niveau du système hydraulique.</li>
                        <li><strong>Allongement de la distance de freinage</strong> : Si vous remarquez que votre véhicule prend plus de temps pour s'arrêter, il est probable que vos freins soient usés et qu'ils nécessitent une intervention immédiate.</li>
                    </ul>
                </div>
                <img src={ImgFreinage2} loading="lazy" className="animation-img-articles w-2/4" alt="Gros plan sur le système de freinage d'une voiture avec les disques et plaquettes de frein en cours de remplacement"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Notre service de freinage comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Contrôle et inspection des freins</h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Nous effectuons une <strong>inspection complète du système de freinage</strong>, incluant les <strong>plaquettes de frein</strong>, les <strong>disques</strong>, les <strong>étriers</strong>, et le <strong>liquide de frein</strong>. Nous vérifions leur usure, leur efficacité et leur état général pour déterminer si des réparations ou des remplacements sont nécessaires.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Remplacement des plaquettes de frein</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Les plaquettes de frein sont les composants qui <strong>s'usent le plus rapidement dans le système de freinage</strong>. Si elles sont trop usées, elles ne seront <strong>plus efficaces</strong> et pourraient <strong>endommager vos disques</strong>. Nous vous offrons un service rapide de remplacement des plaquettes, avec des pièces de haute qualité adaptées à votre véhicule.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Changement des disques de frein</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Si vos disques de frein sont <strong>fissurés</strong>, <strong>trop usés</strong> ou <strong>déformés</strong>, il est essentiel de les remplacer pour garantir un <strong>freinage efficace</strong> et <strong>sécurisé</strong>. Nous vous proposons le remplacement des disques de frein avec des pièces conformes aux spécifications constructeur.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="font-semibold text-sm">Purge et remplacement du liquide de frein</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Le liquide de frein joue un rôle essentiel dans le bon fonctionnement de votre système de freinage. S'il est <strong>vieux</strong> ou <strong>contaminé</strong>, il peut affecter les <strong>performances de freinage</strong>. Nous procédons à la <strong>purge</strong> et au <strong>remplacement du liquide de frein</strong> pour vous assurer un freinage réactif et sans danger.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="font-semibold text-sm">Réparation des étriers de frein</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Les <strong>étriers de frein</strong> sont responsables de <strong>presser les plaquettes contre les disques pour ralentir ou arrêter le véhicule</strong>. Si vos étriers sont défectueux, cela peut <strong>affecter votre capacité à freiner correctement</strong>. Nous inspectons et réparons les étriers de frein pour garantir une action de freinage précise et fiable.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>À quelle fréquence dois-je remplacer mes plaquettes de frein ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Il est recommandé de remplacer les plaquettes de frein tous les <strong>30 000 à 50 000 kilomètres</strong>, mais cela peut varier en fonction de votre <strong>style de conduite</strong> et des <strong>conditions de la route.</strong>
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen7(!isOpen7)}>
                    <h4 className="text-sm"><strong>Q: </strong>Quels sont les signes que mes disques de frein doivent être changés ?</h4>
                    {isOpen7 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen7}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Si vous entendez un <strong>grincement métallique</strong> ou ressentez des <strong>vibrations lors du freinage</strong>, il est possible que vos disques de frein soient <strong>usés</strong> ou <strong>endommagés</strong> et qu'ils doivent être remplacés.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen8(!isOpen8)}>
                    <h4 className="text-sm"><strong>Q: </strong>Pourquoi ma pédale de frein est-elle molle ?</h4>
                    {isOpen8 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen8}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong><strong>Une pédale de frein molle</strong> peut indiquer un <strong>problème de liquide de frein</strong> ou un <strong>défaut dans le système hydraulique</strong>. Nous vous recommandons de faire vérifier vos freins immédiatement.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour un contrôle de vos freins</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Ne prenez pas de risque avec votre sécurité. Si vous avez le moindre doute sur l’état de vos freins, prenez rendez-vous dès aujourd'hui pour un contrôle complet au Garage Ferrand. Contactez-nous au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour planifier une inspection rapide et fiable de votre système de freinage.</p>
                </div>
            </div>
        </div>
    );
}

export default Freinage;