import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgVidange1 from "../assets/images/vidange-1.webp";
import ImgVidange2 from "../assets/images/vidange-2.webp";

function Vidange () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);
    const [isOpen7, setIsOpen7] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Révision / Vidange</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Révision / Vidange</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgVidange1} loading="lazy" className="animation-img-articles w-2/4" alt="Vue détaillée d'une vidange"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Révision / Vidange - Entretien régulier chez Garage Ferrand</h3>
                    <p className="text-center md:text-left mt-2">Nous savons que la révision et la vidange régulières de votre véhicule sont essentielles pour <strong>assurer son bon fonctionnement</strong> et <strong>prolonger sa durée de vie</strong>. Nos techniciens spécialisés effectuent un contrôle complet de votre véhicule lors de chaque révision pour <strong>garantir une performance optimale</strong>.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Pourquoi faire une révision régulière de votre véhicule ?</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Prévenir les pannes</strong> : Une révision permet d'identifier les signes avant-coureurs de problèmes mécaniques et d'éviter des réparations coûteuses.</li>
                        <li><strong>Améliorer la sécurité</strong> : Nous vérifions les éléments essentiels comme les freins, les pneus, les suspensions et l'éclairage pour garantir votre sécurité sur la route.</li>
                        <li><strong>Optimiser la consommation de carburant</strong> : Une vidange et un entretien moteur réguliers permettent à votre véhicule de consommer moins de carburant en améliorant son efficacité.</li>
                        <li><strong>Prolonger la durée de vie du moteur</strong> : En changeant régulièrement l'huile moteur, vous réduisez l'usure et la friction des composants internes, garantissant ainsi une meilleure longévité de votre véhicule.</li>
                    </ul>
                </div>
                <img src={ImgVidange2} loading="lazy" className="animation-img-articles w-2/4" alt="Réalisation d'une vidange sur un véhicule"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Nos services  de révision et de vidange comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Vidange et remplacement du filtre à huile</h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Nous utilisons une <strong>huile de haute qualité</strong> pour assurer le <strong>bon fonctionnement du moteur</strong> tout en respectant les normes du constructeur.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Contrôle des niveaux</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Nos techniciens <strong>vérifient et ajustent les niveaux des liquides (frein, refroidissement, direction assistée, lave-glace)</strong> pour une conduite en toute tranquillité.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Vérification des éléments de sécurité</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>Inspection des freins, amortisseurs, pneus et éclairage</strong> pour garantir un véhicule sûr et conforme aux normes en vigueur.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="font-semibold text-sm">Contrôle des filtres</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                            <strong>Remplacement des filtres à air, à carburant et habitacle</strong> si nécessaire, pour améliorer la performance du moteur et la qualité de l'air dans l'habitacle.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="text-sm"><strong>Q: </strong>À quelle fréquence dois-je faire la vidange de mon véhicule ?</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>La vidange est généralement recommandée <strong>tous les 10 000 à 15 000 kilomètres</strong> ou <strong>une fois par an</strong>, selon votre usage.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>Quels sont les signes que mon véhicule a besoin d'une vidange ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Des signes tels qu'un <strong>moteur plus bruyant</strong>, une <strong>perte de performance</strong>, ou une <strong>consommation accrue de carburant</strong> peuvent indiquer qu'une vidange est nécessaire. Il est essentiel de ne pas négliger ces signaux pour éviter une usure prématurée du moteur.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen7(!isOpen7)}>
                    <h4 className="text-sm"><strong>Q: </strong>En quoi consiste une révision complète de véhicule ?</h4>
                    {isOpen7 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen7}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Une révision complète comprend la <strong>vidange d'huile</strong>, le <strong>changement des filtres (air, huile, carburant, habitacle)</strong>, le <strong>contrôle des niveaux de liquide</strong>, la <strong>vérification des freins, pneus et suspensions</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour la révision et la vidange de votre véhicule</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Assurez-vous que votre moteur fonctionne de manière optimale en effectuant régulièrement la révision et la vidange de votre véhicule. N’attendez pas que des problèmes apparaissent. Contactez dès aujourd'hui Garage Ferrand au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour demander un devis. </p>
                </div>
            </div>
        </div>
    );
}

export default Vidange;