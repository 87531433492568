import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./Navbar";
import Home from "./Home";
import Services from "./Services";
import Diagnostic from "./services/Diagnostic";
import Footer from "./Footer";
import Partenaires from "./components/Partenaires";
import Climatisation from "./services/Climatisation";
import Pneumatique from "./services/Pneumatique";
import Freinage from "./services/Freinage";
import ReglageGeometrie from "./services/ReglageGeometrie";
import Distribution from "./services/Distribution";
import Embrayage from "./services/Embrayage";
import Vidange from "./services/Vidange";
import PareBrise from "./services/PareBrise";
import Eclairage from "./services/Eclairage";
import Contact from "./Contact";
import MentionsLegales from "./mentions_legales";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/services/vidange' element={<Vidange/>}/>
          <Route path='/services/diagnostic' element={<Diagnostic/>}/>
          <Route path='/services/climatisation' element={<Climatisation/>}/>
          <Route path='/services/pneumatique' element={<Pneumatique/>}/>
          <Route path='/services/freinage' element={<Freinage/>}/>
          <Route path='/services/reglage-geometrie' element={<ReglageGeometrie/>}/>
          <Route path='/services/distribution' element={<Distribution/>}/>
          <Route path='/services/embrayage' element={<Embrayage/>}/>
          <Route path='/services/pare-brise' element={<PareBrise/>}/>
          <Route path='/services/eclairage' element={<Eclairage/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/mentions-legales' element={<MentionsLegales/>}/>

        </Routes>
        <Partenaires/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App; 
 