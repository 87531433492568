import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgEmbrayage1 from "../assets/images/remplacement-embrayage.webp";
import ImgEmbrayage2 from "../assets/images/mecanicien-remplacement-embrayage.webp";

function Embrayage () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);
    const [isOpen7, setIsOpen7] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Embrayage</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Remplacement de votre embrayage </h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgEmbrayage1} loading='lazy' className="animation-img-articles w-2/4" alt="Vue détaillée d'un embrayage usé"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Embrayage - Remplacement de votre embrayage</h3>
                    <p className="text-center md:text-left mt-2">Le système d'embrayage est <strong>essentiel pour transmettre la puissance du moteur aux roues de votre véhicule</strong>. Un embrayage <strong>usé</strong> ou <strong>défectueux</strong> peut <strong>entraîner des difficultés de conduite</strong>, <strong>réduire les performances</strong> et, à terme, <strong>endommager d'autres composants</strong> de votre véhicule.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Quand remplacer votre embrayage ?</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Kilométrage élevé</strong> : En général, un embrayage doit être remplacé tous les 100 000 à 150 000 km, en fonction de votre style de conduite et des conditions de la route.</li>
                        <li><strong>Conduite urbaine fréquente</strong> : Les conducteurs qui passent souvent les vitesses en ville peuvent user leur embrayage plus rapidement. Une vérification régulière est donc recommandée.</li>
                        <li><strong>Signes d'usure</strong> : Si vous remarquez un patinage, des bruits anormaux ou des difficultés à passer les vitesses, il est temps de faire inspecter votre embrayage.</li>
                    </ul>
                </div>
                <img src={ImgEmbrayage2} loading="lazy" className="animation-img-articles w-2/4" alt="Mécanicien qui remplace un embrayage"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Notre service d'embrayage comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Diagnostic complet du système d'embrayage</h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Nous effectuons un diagnostic complet de votre embrayage pour <strong>identifier les signes d'usure</strong> et déterminer s'il doit être remplacé. Nous vérifions les composants clés, tels que les <strong>disques</strong>, le <strong>câble d'embrayage</strong>, la <strong>butée</strong>, et le <strong>volant moteur</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Remplacement du kit d'embrayage complet</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Si votre embrayage est fortement endommagé ou si plusieurs composants sont usés, nous vous proposons un remplacement complet du kit d'embrayage (disques, butée, câble) pour vous assurer une réparation durable.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Réglage ou remplacement du câble d'embrayage</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>Si votre pédale d'embrayage devient molle ou difficile à enfoncer</strong>, il est possible que <strong>le câble d'embrayage soit détendu ou usé</strong>. Nous procédons à un réglage ou à un remplacement du câble pour vous garantir un fonctionnement fluide.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="font-semibold text-sm">Remplacement du volant moteur</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>Le volant moteur joue un rôle crucial dans le bon fonctionnement de votre embrayage</strong>. Si celui-ci est usé ou endommagé, il peut <strong>provoquer des vibrations et affecter la transmission de puissance</strong>. Nous procédons au remplacement du volant moteur si nécessaire.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="text-sm"><strong>Q: </strong>Combien de temps dure un embrayage ?</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>La durée de vie moyenne d'un embrayage est de <strong>100 000 à 150 000 kilomètres</strong>. Cependant, <strong>cela dépend de votre style de conduite</strong> et <strong>des conditions routières</strong>. Une conduite en ville ou des démarrages fréquents peuvent accélérer l'usure.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>Quels sont les signes d'un embrayage usé ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Les signes d'un embrayage usé incluent <strong>le patinage</strong>, des <strong>bruits anormaux</strong> lors des changements de vitesse, une <strong>pédale d'embrayage molle ou dure</strong>, et des <strong>difficultés à passer les vitesses</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen7(!isOpen7)}>
                    <h4 className="text-sm"><strong>Q: </strong>Comment entretenir son embrayage pour prolonger sa durée de vie ?</h4>
                    {isOpen7 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen7}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong> Pour prolonger la durée de vie de votre embrayage, il est conseillé d'<strong>adopter une conduite souple en évitant les démarrages brusques et les à-coups</strong>. Il est également recommandé <strong>d'éviter de garder le pied sur la pédale d'embrayage</strong> lorsque ce n'est pas nécessaire, car cela peut accélérer l'usure.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour un diagnostic de votre embrayage</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Si vous remarquez des signes d'usure sur votre embrayage, n'attendez pas que la situation empire. Contactez dès aujourd'hui Garage Ferrand au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour un diagnostic complet ou un remplacement d'embrayage rapide et fiable.</p>
                </div>
            </div>
        </div>
    );
}

export default Embrayage;