import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgDiagnostic1 from "../assets/images/mecanicien-diagnostic-moteur.webp";
import ImgDiagnostic2 from "../assets/images/valise-diagnostic.webp";

function Diagnostic () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);
    const [isOpen7, setIsOpen7] = useState(false);
    const [isOpen8, setIsOpen8] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Diagnostic</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Diagnostic automobile chez Garage Ferrand</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgDiagnostic1} loading='lazy' className="animation-img-articles w-2/4" alt="Mécanicien qui réalise un diagnosctic"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Diagnostic automobile chez Garage Ferrand</h3>
                    <p className="text-center md:text-left mt-2">Le <strong>diagnostic</strong> automobile est une <strong>étape cruciale</strong> pour garantir le bon fonctionnement de votre véhicule. Que vous remarquiez des <strong>signes d'alerte</strong> ou souhaitiez simplement vous assurer que <strong>tout fonctionne correctement</strong>, notre équipe de mécaniciens spécialisés est là pour vous.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Pourquoi faire un diagnostic automobile ?</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Prévenir des pannes coûteuses</strong> : Un diagnostic régulier peut détecter des problèmes mineurs avant qu'ils ne s'aggravent, vous permettant d'économiser sur des réparations plus importantes.</li>
                        <li><strong>Assurer votre sécurité</strong> : Un diagnostic peut identifier des défaillances critiques, telles que des problèmes de freinage ou de direction, qui pourraient mettre en danger votre sécurité et celle des autres usagers de la route.</li>
                        <li><strong>Optimiser les performances</strong> : En identifiant des éléments défectueux comme des capteurs ou des systèmes de gestion moteur, nous pouvons optimiser les performances de votre véhicule pour une conduite plus fluide et plus économique.</li>
                    </ul>
                </div>
                <img src={ImgDiagnostic2} loading='lazy' className="animation-img-articles w-2/4" alt="Valise pour réaliser le diagnostic"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Notre service de diagnostic comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Diagnostic moteur</h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>Le moteur est le cœur de votre véhicule</strong>, et un problème au niveau du moteur peut affecter l'ensemble de votre voiture. Nos équipements nous permettent de scanner le système de gestion moteur et d'identifier des anomalies comme des <strong>capteurs défaillants</strong>, des <strong>dysfonctionnements dans le système de carburant</strong>, ou des <strong>problèmes d'allumage</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Diagnostic électrique</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Les problèmes électriques sont souvent difficiles à détecter sans un équipement spécialisé. Nous analysons l'ensemble du système électrique de votre véhicule, incluant la <strong>batterie</strong>, les <strong>phares</strong>, les <strong>feux de signalisation</strong>, ainsi que les éléments de confort comme la <strong>climatisation</strong> et les <strong>vitres électriques</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Diagnostic électronique</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Les véhicules modernes sont équipés de nombreux systèmes électroniques qui nécessitent une attention particulière. Nous vérifions <strong>les systèmes d'assistance à la conduite (ABS, ESP)</strong>, les <strong>airbags</strong>, et les <strong>systèmes de gestion des performances</strong> pour nous assurer qu'ils fonctionnent comme prévu.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="font-semibold text-sm">Lecture des codes d'erreurs OBD</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        En cas de panne, <strong>votre voiture enregistre des codes d'erreurs via le système OBD (On-Board Diagnostics)</strong>. Nos techniciens lisent et interprètent ces codes pour diagnostiquer rapidement les problèmes liés au moteur, à la transmission, ou à d'autres composants essentiels.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="font-semibold text-sm">Diagnostic avant contrôle technique</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Si votre véhicule doit passer un <strong>contrôle technique</strong>, nous proposons un <strong>diagnostic complet</strong> pour nous assurer qu'il est en état de réussir le test. Nous identifions les points critiques qui pourraient entraîner un échec et les corrigeons avant la visite.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>Que faire si une lumière s'allume sur mon tableau de bord ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Si un témoin lumineux s'allume sur votre tableau de bord, il est important de ne pas l'ignorer. <strong>Prenez rendez-vous rapidement pour un diagnostic</strong> afin de déterminer la cause et <strong>éviter des dommages plus graves</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen7(!isOpen7)}>
                    <h4 className="text-sm"><strong>Q: </strong>À quelle fréquence dois-je faire un diagnostic ?</h4>
                    {isOpen7 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen7}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Il est conseillé de faire un diagnostic <strong>au moins une fois par an</strong>, ou <strong>avant tout long trajet</strong>, pour s'assurer que votre véhicule est en bon état. De plus, si vous remarquez des <strong>signes inhabituels (bruits, perte de puissance)</strong>, un diagnostic est recommandé immédiatement.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen8(!isOpen8)}>
                    <h4 className="text-sm"><strong>Q: </strong>Combien de temps dure un diagnostic automobile ?</h4>
                    {isOpen8 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen8}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong> Un diagnostic complet prend généralement entre <strong>30 minutes et 1 heure</strong>, selon la complexité des systèmes à vérifier. Nos techniciens vous tiendront informé de chaque étape.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Contactez-nous pour un diagnostic complet</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Si vous remarquez des problèmes sur votre véhicule ou souhaitez simplement vous assurer que tout est en ordre, n'attendez pas que la situation empire. Prenez rendez-vous dès aujourd'hui pour un diagnostic complet au Garage Ferrand. Contactez-nous au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour planifier une intervention rapide et efficace.</p>
                </div>
            </div>
        </div>
    );
}

export default Diagnostic;