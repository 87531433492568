import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgPareBrise1 from "../assets/images/mecanicien-enleve-pare-brise.webp";
import ImgPareBrise2 from "../assets/images/pose-pare-brise.webp";

function PareBrise () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Pare-Brise</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Remplacement de Pare-brise</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgPareBrise1} loading="lazy" className="animation-img-articles w-2/4" alt="Mécanicien qui enlève un pare-brise"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Remplacement de Pare-brise chez Garage Ferrand</h3>
                    <p className="text-center md:text-left mt-2">Chez Garage Ferrand, nous vous garantissons un <strong>remplacement de pare-brise rapide et efficace</strong> pour assurer votre sécurité et maintenir une visibilité optimale sur la route. <strong>Que votre pare-brise soit fissuré, brisé ou trop endommagé pour être réparé</strong>, nos mécaniciens se chargent de le <strong>retirer et d'installer un nouveau pare-brise</strong> en respectant les normes de qualité les plus strictes.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Pourquoi remplacer votre pare-brise ?</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Sécurité accrue</strong> : Un pare-brise endommagé peut nuire à la rigidité de la structure de votre véhicule, augmentant les risques en cas d'accident.</li>
                        <li><strong>Visibilité optimale</strong> : Une fissure ou un éclat peut gêner votre champ de vision et créer des reflets gênants, surtout sous la lumière du soleil ou de nuit.</li>
                        <li><strong>Conformité légale</strong> : Rouler avec un pare-brise endommagé peut entraîner une contravention lors du contrôle technique et compromettre la validité de votre assurance.</li>
                    </ul>
                </div>
                <img src={ImgPareBrise2} loading="lazy" className="animation-img-articles w-2/4" alt="Nouveau pare-brise fixé à l'aide de ventouse"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Notre service de remplacement de pare-brise comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Démontage et installation d'un nouveau pare-brise</h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                            Nous nous occupons du <strong>démontage complet de votre ancien pare-brise et de l'installation d'un neuf</strong>, adapté à votre véhicule et conforme aux normes constructeurs.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Calibrage des caméras ADAS</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Si votre véhicule est équipé de systèmes d'assistance à la conduite (caméras ADAS), <strong>nous collaborons avec des experts partenaires pour assurer le calibrage des caméras</strong> une fois le pare-brise installé, garantissant un fonctionnement optimal de vos aides à la conduite.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Qualité et rapidité</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Nos techniciens qualifiés vous garantissent un remplacement rapide, tout en utilisant des pare-brises et des matériaux de la meilleure qualité pour assurer votre sécurité à long terme.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="text-sm"><strong>Q: </strong>Mon pare-brise est fissuré, dois-je le réparer ou le remplacer ?</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Si la fissure est <strong>plus grande qu'une pièce de deux euros ou se trouve dans le champ de vision du conducteur</strong>, il est souvent recommandé de remplacer le pare-brise. Pour les dommages mineurs, une réparation peut suffire, mais un contrôle professionnel est nécessaire pour évaluer la meilleure solution.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="text-sm"><strong>Q: </strong>Combien de temps dure le remplacement d'un pare-brise ?</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Le remplacement d'un pare-brise prend généralement <strong>entre 2 et 3 heures</strong>, selon le modèle de votre véhicule. Cela inclut le <strong>démontage de l'ancien pare-brise</strong>, la <strong>pose du nouveau</strong>, ainsi que le <strong>temps de séchage nécessaire</strong> avant de pouvoir conduire à nouveau.
                        </p>
                    </div>00
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>Dois-je faire calibrer les caméras après le remplacement de mon pare-brise ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Oui, <strong>si votre véhicule est équipé de systèmes d'assistance à la conduite (ADAS)</strong>, <strong>un recalibrage des caméras est nécessaire</strong> après le remplacement du pare-brise pour garantir que les systèmes de sécurité fonctionnent correctement. Nous travaillons avec des experts pour effectuer ce calibrage.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour le remplacement de votre pare-brise</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Assurez-vous que votre pare-brise est en parfait état pour garantir votre sécurité sur la route. Si vous avez une fissure ou un éclat, n'attendez pas que le problème s'aggrave. Contactez dès aujourd'hui Garage Ferrand au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour un diagnostic complet et un réglage de géométrie précis.</p>
                </div>
            </div>
        </div>
    );
}

export default PareBrise;