import { IoMail } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import Logo from "./assets/images/bosch-car-service-logo.svg";

function Footer () {
    return (
        <div >
            <div className="bg-bg mt-12 flex flex-col gap-4 items-center justify-around py-8 px-10 md:px-40">
                <img src={Logo} loading='lazy' alt="Logo Bosch Car Service" className="w-20"/>
                <div className="flex flex-col md:flex-row items-center gap-4 md:gap-10">
                    <a href="/">Accueil</a>
                    <a href="/services">Nos services</a>
                    <a href="/contact">Contact</a>
                </div>
                <div className="flex flex-col lg:flex-row items-center text-center justify-around gap-4 md:gap-10">
                    <a href="https://www.google.fr/maps/place/Garage+Pascal+Ferrand/@47.7733533,-0.2146748,17z/data=!4m6!3m5!1s0x481117e7ef5eb399:0x53afc46dbd157bbd!8m2!3d47.7733497!4d-0.2120999!16s%2Fg%2F1tdq0h_y?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D" target='_blank' rel="noreferrer" className="flex items-center gap-2">
                        <IoLocationSharp className="text-primary text-2xl md:text-xl" aria-label="Icône Localisation"/>
                        <p>30 r Portugal Zac Ouest Parc, 72200 le Bailleul</p>
                    </a>
                    <a href="tel:0243455023" className="flex items-center gap-2">
                        <FaPhone className="text-primary text-xl" aria-label="Icône Téléphone"/>
                        <p>02 43 45 50 23</p>
                    </a>
                    <a href="mailto:ferrand87@orange.fr" className="flex items-center gap-2">
                        <IoMail className="text-primary text-xl" aria-label="Icône Email"/>
                        <p>ferrand84@orange.fr</p>
                    </a>
                    <a href="https://www.facebook.com/pascal.ferrand.50" target="_blank" rel="noreferrer" className="flex items-center gap-2">
                        <FaFacebook className="text-primary text-xl" aria-label="Icône Facebook"/>
                        <p>Pascal Ferrand</p>
                    </a>
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 md:gap-0 items-center justify-evenly bg-primary text-white py-4 md:py-2 px-10">
                <p><a href="/mentions-legales">Mentions légales</a></p>
                <p>Copyright © 2024 | <a href="https://evaferrand.fr" target="_blank" rel="noreferrer">Eva Ferrand</a></p>
                <p><a href="https://www.boschcarservice.com/fr/fr" target="_blank" rel="noreferrer">Bosch Car Service</a></p>
            </div> 
        </div>
    );
}

export default Footer;