import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgReglageGeometrie1 from "../assets/images/controle-reglage-geometrie.webp";
import ImgReglageGeometrie2 from "../assets/images/mécanicien-controle-reglage-geometrie.webp";

function ReglageGeometrie () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Réglage géométrie</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Optimisation de l'alignement de vos roues</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgReglageGeometrie1} loading="lazy" className="animation-img-articles w-2/4" alt="Véhicule en cours de réglage de géométrie"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Réglage de géométrie - Optimisation de l'alignement de vos roues</h3>
                    <p className="text-center md:text-left mt-2">Le réglage de la géométrie des roues, aussi appelé alignement des roues, est une opération essentielle pour <strong>assurer une conduite sûre</strong>, <strong>confortable</strong> et <strong>économique</strong>. Un mauvais alignement peut entraîner une <strong>usure prématurée des pneus</strong>, des <strong>problèmes de tenue de route</strong>, et une <strong>surconsommation de carburant.</strong></p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Quand faut-il effectuer un réglage de géométrie ?</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Après un choc ou un accident</strong> : Si vous avez heurté un trottoir, un nid-de-poule ou été impliqué dans un accident, il est possible que l'alignement des roues ait été affecté. Un contrôle de la géométrie est alors nécessaire.</li>
                        <li><strong>Usure inégale des pneus</strong> : Si vous remarquez que vos pneus s'usent plus rapidement d'un côté ou de manière irrégulière, cela peut être le signe d’un problème de géométrie.</li>
                        <li><strong>Tirage du véhicule</strong> : Si votre véhicule tend à tirer d'un côté lorsque vous conduisez, cela peut être dû à un mauvais alignement des roues.</li>
                    </ul>
                </div>
                <img src={ImgReglageGeometrie2} loading="lazy" className="animation-img-articles w-2/4" alt="Mécanicien effectuant un contrôle et réglage de la géométrie des roues sur une voiture"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Notre service de réglage de géométrie comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Diagnostic complet de la géométrie des roues </h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Nous effectuons un diagnostic précis à l'aide d'équipements de mesure modernes pour vérifier l'alignement de vos roues. Nous <strong>analysons les principaux angles</strong> de la géométrie, tels que le <strong>carrossage</strong>, la <strong>chasse</strong> et le <strong>parallélisme</strong>, pour identifier les ajustements nécessaires.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Réglage de l'alignement des roues</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>Si des écarts sont détectés</strong> lors du diagnostic, nous procédons à un réglage complet de la géométrie pour rétablir les angles corrects. Cela inclut l'ajustement des <strong>angles de parallélisme et de carrossage</strong> pour assurer un alignement optimal des roues.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Vérification de la suspension</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>Une mauvaise suspension peut affecter l'alignement des roues</strong> et <strong>provoquer une usure irrégulière des pneus</strong>. Nous vérifions l’état des <strong>amortisseurs</strong> et des <strong>ressorts</strong> pour s'assurer que la géométrie de votre véhicule reste stable dans le temps.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="text-sm"><strong>Q: </strong>À quelle fréquence dois-je faire régler la géométrie de mes roues ?</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Il est conseillé de faire vérifier et ajuster la géométrie de vos roues <strong>environ une fois par an</strong> ou <strong>après avoir heurté un obstacle comme un trottoir ou un nid-de-poule</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="text-sm"><strong>Q: </strong>Quels sont les signes d'un problème de géométrie ?</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Les signes courants incluent <strong>une usure irrégulière des pneus</strong>, <strong>un véhicule qui tire d'un côté</strong>, et <strong>des difficultés à maintenir une trajectoire droite</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>Quelle est la différence entre un réglage de géométrie et un équilibrage des roues ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Le <strong>réglage de géométrie</strong> concerne <strong>l'alignement des roues</strong> pour assurer qu'elles soient parallèles entre elles et perpendiculaires au sol, tandis que <strong>l'équilibrage des roues</strong> vise à <strong>répartir uniformément les masses autour des roues</strong> pour éviter les vibrations.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour un réglage de géométrie</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Si vous remarquez des signes de désalignement ou souhaitez simplement optimiser la tenue de route de votre véhicule, contactez dès aujourd'hui Garage Ferrand au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour un diagnostic complet et un réglage de géométrie précis.</p>
                </div>
            </div>
        </div>
    );
}

export default ReglageGeometrie;