import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; 
import { FaComment } from "react-icons/fa";
import ImgPneumatique1 from "../assets/images/mecanicien-enleve-pneu.webp";
import ImgPneumatique2 from "../assets/images/mecanicien-met-pneu.webp";

function Pneumatique () {
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsOpen4] = useState(false);
    const [isOpen5, setIsOpen5] = useState(false);
    const [isOpen6, setIsOpen6] = useState(false);
    const [isOpen7, setIsOpen7] = useState(false);
    const [isOpen8, setIsOpen8] = useState(false);

    return (
        <div className="mx-10 lg:mx-56 3xl:mx-96 pt-32 md:pt-48 flex flex-col items-center">
            <h1 className="text-lg md:text-2xl text-center"><a href="/services" className="text-lg md:text-2xl">Nos Services</a> {'>'} Pneumatique</h1>
            <h2 className="text-sm md:text-lg text-black font-normal text-center">Vérification, Entretien et Remplacement de vos pneus</h2>

            <div className="flex flex-col md:flex-row gap-10 md:gap-0 items-center justify-center mt-10 mb-20">
                <img src={ImgPneumatique1} loading="lazy" className="animation-img-articles w-2/4" alt="Mécanicien qui enlève un pneu"/>
                <div className="md:w-2/4 md:pl-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Pneumatique - Vérification, Entretien et Remplacement </h3>
                    <p className="text-center md:text-left mt-2">Les pneus de votre véhicule jouent un rôle crucial dans votre <strong>sécurité</strong> et votre <strong>confort de conduite</strong>. Une <strong>usure excessive</strong> ou des <strong>pneus mal entretenus</strong> peuvent non seulement compromettre la <strong>tenue de route</strong>, mais aussi augmenter la <strong>consommation de carburant</strong>.</p>
                </div>
            </div>

            <div className="flex flex-col-reverse md:flex-row gap-10 md:gap-0 items-center justify-center mb-20">
                <div className="md:w-2/4 md:pr-24">
                    <h3 className="text-base md:text-lg text-center md:text-left text-primary font-semibold mx-10 md:mx-0">Quand changer vos pneus ?</h3>
                    <ul className="list-disc ml-6 mt-4">
                        <li><strong>Profondeur de la bande de roulement inférieure à 1,6 mm</strong> : Si la profondeur de la bande de roulement de vos pneus est inférieure à 1,6 mm, il est temps de les changer pour garantir une bonne adhérence sur la route.</li>
                        <li><strong>Usure irrégulière</strong> : Si vous constatez une usure inégale de vos pneus, cela peut indiquer un problème d'alignement ou de suspension. Nous vous conseillons de faire vérifier vos pneus immédiatement.</li>
                        <li><strong>Âge des pneus</strong> : Même si vos pneus semblent encore en bon état, il est recommandé de les changer tous les 5 à 6 ans pour éviter des risques de défaillance.</li>
                    </ul>
                </div>
                <img src={ImgPneumatique2} loading="lazy" className="animation-img-articles w-2/4" alt="Mécanicien qui met un pneu"/>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5 text-center">Notre service de pneumatique comporte :</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen1(!isOpen1)}>
                    <h4 className="font-semibold text-sm">Vérification et ajustement de la pression des pneus</h4>
                    {isOpen1 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen1}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Des pneus <strong>sous-gonflés</strong> ou <strong>surgonflés</strong> peuvent entraîner une <strong>usure prématurée</strong> et réduire l'efficacité de votre véhicule. Nous vérifions régulièrement la pression de vos pneus et ajustons celle-ci pour garantir une conduite plus sûre et plus économique.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen2(!isOpen2)}>
                    <h4 className="font-semibold text-sm">Inspection de l'usure des pneus</h4>
                    {isOpen2 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen2}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Une <strong>usure inégale ou excessive</strong> des pneus peut être le signe de problèmes mécaniques tels que des <strong>désalignements ou des amortisseurs défectueux</strong>. Nous réalisons une inspection complète de l'usure de vos pneus pour déterminer si un remplacement est nécessaire et vous conseiller sur les meilleures options.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen3(!isOpen3)}>
                    <h4 className="font-semibold text-sm">Réparation de pneus</h4>
                    {isOpen3 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen3}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        En cas de <strong>crevaison</strong> ou de <strong>dommage mineur</strong>, nous proposons des <strong>services de réparation rapide</strong> pour vous éviter le remplacement immédiat de vos pneus.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen4(!isOpen4)}>
                    <h4 className="font-semibold text-sm">Remplacement de pneus</h4>
                    {isOpen4 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen4}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        Si vos pneus sont <strong>trop usés</strong> ou <strong>endommagés</strong>, nous vous proposons un <strong>service de remplacement</strong> avec un large choix de marques et de modèles.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen5(!isOpen5)}>
                    <h4 className="font-semibold text-sm">Équilibrage des roues</h4>
                    {isOpen5 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen5}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        L'<strong>équilibrage des roues</strong> est essentiel pour assurer une <strong>usure uniforme</strong> de vos pneus et une <strong>conduite sans vibrations</strong>. Nous équilibrons soigneusement vos roues lors de chaque remplacement ou réparation de pneus afin de garantir une conduite stable et confortable.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <h3 className="text-base md:text-lg text-primary font-semibold mb-5">FAQ</h3>
            <div className=" mb-20">
                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen6(!isOpen6)}>
                    <h4 className="text-sm"><strong>Q: </strong>À quelle fréquence dois-je vérifier la pression de mes pneus ?</h4>
                    {isOpen6 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen6}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Il est recommandé de vérifier la pression de vos pneus <strong>tous les mois</strong> ou <strong>avant un long trajet</strong>.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen7(!isOpen7)}>
                    <h4 className="text-sm"><strong>Q: </strong>Comment savoir si mes pneus sont usés ?</h4>
                    {isOpen7 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen7}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong>Si la profondeur de la bande de roulement est <strong>inférieure à 1,6 mm</strong>, vos pneus sont trop usés et doivent être remplacés.
                        </p>
                    </div>
                    </Collapse>
                </div>

                <div className="mb-5 bg-bg rounded-3xl p-5 shadow-md">
                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setIsOpen8(!isOpen8)}>
                    <h4 className="text-sm"><strong>Q: </strong>Que faire en cas de crevaison ?</h4>
                    {isOpen8 ? <FaChevronUp className="text-primary" aria-label="Flèche vers le haut"/> : <FaChevronDown className="text-primary" aria-label="Flèche vers le bas"/>}
                    </div>
                    <Collapse isOpened={isOpen8}>
                    <div className="mt-3 pl-3">
                        <p className="text-primary">
                        <strong>R: </strong><strong>En cas de crevaison, venez nous voir au Garage Ferrand</strong>. Nous déterminerons si le pneu peut être réparé ou s'il doit être remplacé.
                        </p>
                    </div>
                    </Collapse>
                </div>
            </div>

            <div className="flex flex-col md:flex-row bg-bg items-center rounded-3xl p-5 mb-20 shadow-md">
                <FaComment className="w-20 md:w-60 h-24 text-3xl text-primary mx-10" aria-label="Icône Commentaire"/>
                <div>
                    <h3 className="text-base md:text-lg px-4 md:px-0 text-primary font-semibold mb-2 text-center md:text-left">Prenez rendez-vous pour vos pneus</h3>
                    <p className="text-primary text-center md:text-left mt-2 text-sm">Que vous ayez besoin d'une vérification de la pression, d'un remplacement de pneus ou d'une réparation rapide, nous sommes à votre disposition au Garage Ferrand. Contactez-nous dès aujourd'hui au <a href="tel:0243455023"><strong>02 43 45 50 23</strong></a> ou via notre <a href="/contact"><strong>formulaire de contact</strong></a> pour prendre rendez-vous.</p>
                </div>
            </div>
        </div>
    );
}

export default Pneumatique;